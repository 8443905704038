@import url(https://fonts.googleapis.com/css2?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  background-color: white;
}

.shadow {
    display:block;
    position:relative;
}

.shadow img {
    width: 100%;
    height: 100%;
}

.shadow:before {
    display:block;
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    box-shadow:inset 0px 0px 18px 18px rgba(255,255,255,1);
}

.endorsed {
  font-size: 14px;
  line-height: 1em;
  color: rgb(92, 92, 92);
}

.endorsed a {
  color: rgb(92, 92, 92);
}

.endorsed a:hover {
  color: darkblue;
}

section {
  padding: 0 30px;
}

section div {
  max-width: 900px;
  margin: 0 auto;
}

p {
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  line-height: 34px;
}

h2 {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2.5px;
  margin: 0;
}

.about {
  text-align: center;
}

.about p {
  margin: 0 0 30px 0;
}

.experience {
  background-color: rgb(222, 233, 235);
  padding: 30px;
  background-image: URL('/stars.jpg');
  background-position: center;
}

.experienceContentContainer {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}

li {
  margin-top: 20px;
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  line-height: 28px
}

.fillpotImg {
  width: 300px;
  height: 300px;
}

.believe {
  padding: 30px;
}

.believe p {
  margin: 20px 0 20px 40px;
}

.vote {
  padding: 30px 30px 20px 30px;
  background-image: URL('/stripes2.jpg');
  background-position: center;
  background-size: 1750px;
}

.leftSpacing {
  margin-left: 40px;
}

.voteContentContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
  align-items: center;
}

.voteContentContainer p {
  margin: -8px 0 0 10px;
  text-align: center;
}

.voteContentContainer img {
  width: 380px;
  height: 250px;
  margin-right: 10px;
}

@media only screen and (max-width: 812px){
  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
    margin-top: 10px;
  }
  h2 {
    font-size: 24px;
  }
  .experienceContentContainer img {
    width: 250px;
    height: 250px;
  }
  .voteContentContainer img {
    width: 275px;
    height: 200px;
  }
  .about p {
    margin: 0 0 20px 0;
  }
}

@media only screen and (max-width: 646px) {
  .about {
    padding: 0 20px;
  }

  .experience {
    padding: 30px 20px 20px 20px;
  }

  .experience h2 {
    text-align: center;
  }

  .endorsed {
    font-size: 12px;
  }

  .experienceContentContainer {
    margin: 20px 0 0 0;
    flex-direction: column-reverse;
    align-items: center;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }
  
  li {
    margin-left: 20px;
  }

  li:first-of-type{
    margin: 5px 0 5px 20px;
  }

  .believe {
    text-align: center;
    padding: 30px 20px;
  }

  .believe p {
    margin: 20px 0;
  }

  .vote {
    padding: 30px 20px 20px 20px;
  }

  .vote p {
    margin: 0;
  }

  .vote h2 {
    text-align: center;
  }

  .voteContentContainer {
    flex-direction: column;
    margin: 20px 0 20px 0;
  }

  .voteContentContainer img {
    margin: 5px 0 20px 0;
  }
  
  .leftSpacing { 
    margin: 0;
  }
}

@media only screen and (max-width: 812px) {
  p {
    font-size: 15px;
    line-height: 28px;
  }
  li {
    font-size: 15px;
    line-height: 28px;
  }
  h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .shadow:before {
    box-shadow:inset 0px 0px 15px 15px rgba(255,255,255,1);
  }
}

@media only screen and (max-width: 500px) {
  .shadow:before {
    box-shadow:inset 0px 0px 12px 12px rgba(255,255,255,1);
  }
}

@media only screen and (max-width: 400px) {
  .shadow:before {
    box-shadow:inset 0px 0px 10px 10px rgba(255,255,255,1);
  }
}

@media only screen and (max-width: 430px) {

}
